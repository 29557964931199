import { basename } from 'pathe';

interface ImagesComposable {
  getImageSrc: (fileName: string) => string | undefined;
}

/**
 * @deprecated use useStaticImage instead
 */
function useImages(): ImagesComposable {
  const images = import.meta.glob('~/assets/images/**/*.(png|jpeg|svg)', {
    eager: true,
  });
  const getImageSrc = (fileName: string): string | undefined => {
    for (const path in images) {
      if (images.hasOwnProperty(path)) {
        // unknown type is required here to change the final type as typescript thinks that images.value[path] is a function, it is not.
        const image: unknown = images[path];
        const imagePath = (image as { default: string }).default;
        const regex = new RegExp(basename(fileName) + '$');
        if (regex.test(basename(path))) {
          return imagePath;
        }
      }
    }
    return undefined;
  };
  return { getImageSrc };
}

export { useImages };

export function useStaticImage(
  src: string,
  hasNlVariant = false,
): string | undefined {
  const img = useImage();
  const siteIdent = useSiteIdent();
  const folder = siteIdent.startsWith('minilu') ? 'minilu' : 'vdv';
  const internalSrc =
    hasNlVariant && siteIdent === SiteIdent.miniluNl
      ? src.replace('.', '_nl.')
      : src;
  return img(`image/${folder}/${internalSrc}`);
}
